<template>
  <div class="container"><div class="language-switch-wrapper"></div></div>
  <Header v-if="isHeaderVisible"/>
  <div class="container main" :class="{'space-for-footer':!notLoggedIn, 'cart':isCart}">
    <router-view/>
  </div>
  <CookieLayer/>
  <div v-if="notLoggedIn">
    <Footer/>
  </div>
  <div v-else>
    <Sidebar/>
    <FunctionalFooter/>
  </div>
</template>

<script>
import Header from './views/components/layout/Header/Header'
import Footer from './views/components/layout/Footer/Footer'
import FunctionalFooter from "@/views/components/layout/Footer/FunctionalFooter";
import Sidebar from "@/views/components/ui/Sidebar";
import CookieLayer from "@/views/components/ui/CookieLayer";

export default {
  components: {
    Sidebar,
    FunctionalFooter,
    Header,
    Footer,
    CookieLayer
  },
  computed: {
    isHeaderVisible() {
      return (
          this.$router.currentRoute.value.name === 'Register'
      )
    },
    notLoggedIn() {
      return (
          this.$router.currentRoute.value.path === '/login' ||
          this.$router.currentRoute.value.path === '/logout' ||
          this.$router.currentRoute.value.path === '/registrierung' ||
          this.$router.currentRoute.value.path === '/' ||
          (this.$router.currentRoute.value.name === 'NotFound' && !localStorage.getItem('isLoggedIn'))
      )
    },
    isCart() {
      return this.$router.currentRoute.value.path === '/warenkorb'
    }
  },
  mounted() {
    this.loadGTranslate()
  },
  methods: {
    loadGTranslate() {
      window.gtranslateSettings = {
        "default_language": "de",
        "native_language_names": true,
        "detect_browser_language": true,
        "languages": ["de", "en", "uk", "ru", "ar", "pl", "cs", "tr", "fr", "es", "pt"],
        "wrapper_selector": ".language-switch-wrapper",
        "flag_size": 24,
        "switcher_horizontal_position": "inline",
        "flag_style": "3d"
      };

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://cdn.gtranslate.net/widgets/latest/dwf.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }

}
</script>

<style lang="scss" scoped>
.container {
  &.main {
    min-height: 50vh;
    background: $secondary;
    box-shadow: 0 0 $shadow-blur $shadow-color;
  }

  &.space-for-footer {
    padding-bottom: 100px;
  }

  .language-switch-wrapper {
    top: 5px;
    right:5px;
    position: absolute;
  }
}
</style>
